'use client';

import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { Icon } from '@/components/icon';
import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { useRedeemAgainItems } from '@/hooks/order_items/use-redeem-again-items';
import useProduct, {
  OrderItemTypeMapper,
  ProductType,
} from '@/hooks/products/use-product';
import { useRouter } from '@/i18n/navigation';
import { orderItemTypeToTypeInPath } from '@/schema/order/common';
import { RedeemAgainOrderItem } from '@/schema/order/order-item';
import { useTranslation } from '@/translation/translation';
import { PRODUCT_LOGOS_OPTIONS, generateImageUrl } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { RedeemAgainBannerSkeleton } from './redeem-again-banner-skeleton';

type ProductData = {
  productType: ProductType;
  productId: string;
  link: string;
};

const ProductNotAvailable = dynamic(
  () =>
    import('./product-not-available').then(
      (module) => module.ProductNotAvailable,
    ),
  {
    ssr: false,
  },
);

export function RedeemAgainBanner() {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    data: orderItems,
    isLoading,
    isError,
  } = useRedeemAgainItems({
    pageSize: 3,
    pageNumber: 1,
  });

  const [clickedProductData, setClickedProductData] = useState<
    ProductData | undefined
  >();

  const { status } = useProduct(
    clickedProductData?.productId || '',
    clickedProductData?.productType || 'gift_card',
    !!clickedProductData?.productId,
  );

  useEffect(() => {
    if (status === 'success' && clickedProductData?.link) {
      router.push(clickedProductData.link);
    }
  }, [status, clickedProductData, router]);

  if (isLoading) return <RedeemAgainBannerSkeleton />;

  if (isError || !orderItems || orderItems.length === 0) {
    return null;
  }

  return (
    <>
      {status === 'error' && (
        <ProductNotAvailable
          onClose={() => {
            setClickedProductData(undefined);
          }}
        />
      )}
      <HomepageProductsCarousel
        titleComponent={
          <div className="flex items-center justify-start gap-4">
            <p className="text-heading-2">{t('Redeem again')}</p>
            <RcLink
              href={'/rewards-summary/my-rewards'}
              variant={RcLinkVariant.SECONDARY}
              className="text-secondary"
            >
              {t('View all redemptions')}
            </RcLink>
          </div>
        }
        className="gap-6 container-responsive"
        carouselWrapperClassName="lg:grid lg:grid-cols-3 gap-6"
        hideOnNotSlidable
      >
        {orderItems.map((item) => (
          <button
            key={item.id}
            className={cn(
              'relative overflow-hidden',
              'flex w-[330px] shrink-0 items-center justify-start gap-4 lg:w-auto',
              'rounded-custom bg-neutral-200 p-3 pr-5',
            )}
            onClick={() => {
              setClickedProductData(getProductData(item));
            }}
            aria-label={`${t('Redeemed product')}, ${item.data.productName}`}
          >
            <img
              src={generateImageUrl({
                url: item.data.productImageUrl,
                ...PRODUCT_LOGOS_OPTIONS,
              })}
              className="h-12 rounded"
              alt=""
            />

            <p className="line-clamp-2 text-left">{item.data.productName}</p>

            <Icon
              name="clock-rotate-left"
              className="absolute -right-2 ml-auto h-10 w-10 text-neutral-300"
            />
          </button>
        ))}
      </HomepageProductsCarousel>
    </>
  );
}

function getProductLink(orderItem: RedeemAgainOrderItem) {
  // NN introduced a new field `originalCardId` referred to the original gift card id from listing endpoint
  // cardId is the denominationId FE used to checkout
  // NN can't backfill the originalCardId for old orders/redemptions so we need to handle both cases

  const productId =
    orderItem.type === 'gift_card_order_item'
      ? orderItem.data.originalCardId || orderItem.data.cardId
      : orderItem.data.loyaltyProgramId;

  return [
    'products',
    orderItemTypeToTypeInPath[orderItem.type],
    productId,
  ].join('/');
}

function getProductData(orderItem: RedeemAgainOrderItem) {
  const productId =
    orderItem.type === 'gift_card_order_item'
      ? orderItem.data.originalCardId || orderItem.data.cardId
      : orderItem.data.loyaltyProgramId;

  const productType = OrderItemTypeMapper[orderItem.type];
  return {
    productType,
    productId,
    link: getProductLink(orderItem),
  };
}
