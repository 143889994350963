import { Icon } from '@/components/icon';
import { MenuItemType } from '@/config-schema/navigation';
import Drawer, {
  DrawerBody,
  DrawerHeader,
} from '@/design-system-components/drawer/drawer';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { Button, DialogTrigger } from 'react-aria-components';
import { QuicklinklItem } from './quicklink-item';

const NO_RECOMMENDED_LINKS = 3;

export function MobileQuickLink({
  className,
  headerLinks,
}: {
  headerLinks: MenuItemType[];
  className?: string;
}) {
  const count = headerLinks.length;
  if (count <= 1) return null;

  const showMoreButton = count >= NO_RECOMMENDED_LINKS + 2;
  const twoItems = count === 2;
  const threeItems = count === 3;
  const wrapperClassName = threeItems
    ? 'grid grid-cols-3 gap-[18px]'
    : twoItems
    ? 'grid grid-cols-2 gap-[18px]'
    : 'grid grid-cols-4 gap-[18px]';

  return (
    <div
      // TODO(khoi): Remove this magic number 18px
      className={cn(wrapperClassName, className, 'container-responsive')}
    >
      {headerLinks
        .slice(0, showMoreButton ? NO_RECOMMENDED_LINKS : count)
        .map((menuItem) => (
          <QuicklinklItem
            key={menuItem.itemType}
            menuItem={menuItem}
            className={twoItems ? 'flex-row' : 'flex-col'}
            titleClassName="line-clamp-1 text-sm font-normal"
          />
        ))}
      {showMoreButton && <MoreButton menuItems={headerLinks} />}
    </div>
  );
}

export function MoreButton({ menuItems }: { menuItems: MenuItemType[] }) {
  const { t } = useTranslation();
  return (
    <DialogTrigger>
      <Button className="flex flex-col items-center justify-center gap-2">
        {/* TODO(khoi): Remove this hard-coded magic color */}
        <div className="flex h-10 w-10 rounded-full bg-[#E9EAEF] p-3">
          <Icon name="shapes" className="h-5 w-5" />
        </div>
        <p>{t('More')}</p>
      </Button>
      <Drawer>
        {({ close }) => {
          return (
            <>
              <DrawerHeader className="flex-row justify-end" />
              <DrawerBody>
                <p className="pb-4 text-heading-2">
                  {t('Recommended for you')}
                </p>
                <div className="grid grid-cols-4 gap-[18px]">
                  {menuItems.slice(0, NO_RECOMMENDED_LINKS).map((menuItem) => (
                    <QuicklinklItem
                      className="flex-col"
                      menuItem={menuItem}
                      key={menuItem.itemType}
                      titleClassName="line-clamp-1 text-sm font-normal"
                    />
                  ))}
                </div>

                <p className="pb-4 pt-8 text-heading-2">{t('Other rewards')}</p>
                <div className="grid grid-cols-4 gap-[18px]">
                  {menuItems.slice(NO_RECOMMENDED_LINKS).map((menuItem) => (
                    <QuicklinklItem
                      className="flex-col"
                      menuItem={menuItem}
                      key={menuItem.itemType}
                      titleClassName="line-clamp-1 text-sm font-normal"
                    />
                  ))}
                </div>
              </DrawerBody>
            </>
          );
        }}
      </Drawer>
    </DialogTrigger>
  );
}
